@import 'config'; /* environment config */

@import '../colors';
@import '../variables';
@import '../mixin';

.login {
  .form-group >[class*="col-"] + .ws-errorbox {
    padding: 0 15px;
  }

  .form-group.ws-invalid {
    .user-error {
      border-color: $color-errors;
    }
  }

  .input-error {
    color: $color-errors;
  }

  .ws-errorbox p {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .form-control:focus {
    box-shadow: none;
    border: 1px solid $color-border-price-link;
  }

  &__form-errors {
    @extend %font-semi-bold;

    color: $color-errors;
  }

  &__form {
    max-width: 450px;
    padding: 40px 0 80px 0;
  }

  &__form-title {
    @extend %font-semi-bold;

    font-size: 30px;
    padding-bottom: 30px;
    color: $color-gray-800;
    border-bottom: 1px solid $color-spacing-text;
  }

  &__form-label {
    @extend %font-semi-bold;
  }

  & .btn-secondary--login {
    padding: 0.75rem 2.5rem;
    cursor: pointer;
  }

  &__forgot-password {
    display: block;
    color: $color-tertiary;
    padding: 10px 0 25px 0;
  }

  &__form-input {
    display: block;
    height: 45px;
    width: 100%;
    font-size: 16px;
    padding-left: 15px;
    border: 1px solid $color-border-form-input;

    &::placeholder {
      font-size: 16px;
      color: $color-border-form-input;
    }

    &:focus {
      border: none;
    }
  }

  &__form-info {
    margin-top: 20px;
  }

  &__form-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: $thick-border-width solid $color-border-box-price-plans;
    border-radius: 30px;
    background-color: $color-white;
    overflow: hidden;
    padding: 30px;
  }

  &__container {
    min-height: 500px;
    background-position: center;
  }
}
